@import '../../utils/mixins.module.scss';

.wrapper {
    margin: 80px auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #000000;

    @include tablet-large {
        margin: 80px 0;
    }

    @include tablet-small {
        margin: 44px auto;
    }

    h2 {
        margin-bottom: 10px;
        color: #000000;

        @include mobile {
            max-width: 340px;
        }
    }

    p {
        margin: 20px 0;
    }
}

.content {
    max-width: 894px;
}

.subtitle {
    font-weight: 700;
    font-size: 16px;
    line-height: 100%;
    color: #242424;
    margin: 10px 0;
}

.subheader {
    color: #242424;
    font-size: 16px;
    line-height: 160%;
    font-weight: 600;
    margin-bottom: 15px;

    @include mobile {
        max-width: 300px;
    }
}