@mixin tablet-large {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin tablet-small {
    @media (max-width: 900px) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: 1320px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 600px) {
        @content;
    }
}

@mixin desktop-large {
    @media (min-width: 1600px) {
        @content;
    }
}