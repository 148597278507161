.imageContainer {
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;

    img {
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover;
        width: 100%;
    }

    &::after {
        content: "";
        position: absolute;
        left: -1px;
        top: 0;
        display: block;
        z-index: 1;
        opacity: 0;
        background: transparent;
        transition: 0.3s all linear;
        width: 101%;
        height: 100%;
    }

    &.loading::after {
        opacity: 1;
        background: linear-gradient(90deg, rgba(137, 149, 156, 0.24) 0%, rgba(137, 149, 156,
                    0.1) 30%, rgba(137, 149, 156, 0.24) 100%), white;
        background-size: 300%;
        -webkit-animation: skeleton infinite 3s forwards linear;
        animation: skeleton infinite 3s forwards linear;
    }
}

@-webkit-keyframes skeleton {
    from {
        background-position: 0%;
    }

    to {
        background-position: -300%;
    }
}

@keyframes skeleton {
    from {
        background-position: 0%;
    }

    to {
        background-position: -300%;
    }
}