@import '../../utils/mixins.module.scss';

.wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include mobile {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.footer {
    height: 60px;
    display: flex;
    align-items: center;
    width: 100%;
    background: #FFFFFF;

    a {
        display: flex;
        align-items: center;
    }

    @include mobile {
        height: 108px;
        background: #F3F3F3;
        padding: 20px 16px;
        text-align: center;
    }
}

.text {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-transform: uppercase;
    color: #787878;

    @include mobile {
        order: 2;
        margin-top: 10px;
        max-width: 100%;
        font-size: 12px;
        line-height: 18px;
    }
}