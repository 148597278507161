@import '../../utils/mixins.module.scss';

.container {
    background: #F3F3F3;
    border-radius: 4px;
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin: 80px auto;
    width: 870px;
    position: relative;

    @include tablet-large {
        width: 100%;
    }

    @include tablet-small {
        margin: 0;
        grid-template-columns: 1fr;
        padding: 44px 0;
        justify-content: center;
    }

    @include mobile {
        background-color: #fff;
        padding: 0;
    }

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        background: #D8D8D8;
        width: 1px;
        transform: translate(-50%, -50%);
        height: calc(100% - 64px);
        top: 50%;

        @include tablet-small {
            display: none;
        }
    }
}

.wrapper {
    display: flex;
    justify-content: center;

    @include tablet-small {
        padding: 0;
    }
}

.title {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;

    @include mobile {
        margin-bottom: 16px;
    }

    &.lower {
        text-transform: none;
        margin-bottom: 10px;
    }
}

.form {
    padding: 40px;

    @include tablet-small {
        background-color: white;
        max-width: 480px;
        width: 100%;
        height: 515px;
        padding: 24px;
        margin: 40px auto 0;
    }

    @include mobile {
        margin-top: 0;
        height: 500px;
        background: #F3F3F3;
    }
}

.text {
    padding: 40px;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include tablet-small {
        max-width: 480px;
        padding: 0;
        margin: 0 auto;
    }

    @include mobile {
        padding: 40px 16px;

    }
}

.textWrapper {
    max-width: 350px;

    @include tablet-small {
        max-width: 440px;
    }
}

.submitted {
    padding: 40px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #000000;

    @include tablet-small {
        background-color: white;
        max-width: 480px;
        height: 515px;
        width: 100%;
        padding: 24px 30px;
        margin: 40px auto 0;
    }

    @include mobile {
        margin-top: 0;
        height: 500px;
        padding: 0 16px;
        background: #F3F3F3;
    }
}

.mobileOnly {
    display: none;

    @include mobile {
        display: block;
    }
}

.icon {
    padding: 10px;
    width: 55px;
    height: 55px;
    background: #242424;
    border-radius: 50px;
    margin-bottom: 15px;
}

.linkSection {
    a {
        color: inherit;
    }

    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
}

.input {
    background: #FDFCFC;
    border: 1px solid #E2DCDC;
    border-radius: 4px;
    outline: none;
    max-width: 400px;
    font: inherit;
    font-size: 14px;
    padding: 10px;
    width: 100%;

    &.errorForm {
        background: #f5ebeb;
        border-color: #cd2020;
    }
}

.error {
    position: absolute;
    right: 0;
    font-size: 14px;
    color: #cd2020;
    top: 0;
}

.textarea {
    background: #FDFCFC;
    border: 1px solid #E2DCDC;
    min-height: 60px;
    min-width: 100%;
    border-radius: 4px;
    font: inherit;
    font-size: 14px;
    height: 116px;
    resize: none;
    outline: none;
    max-width: 100%;
    padding: 10px;
    width: 100%;
}

.formRow {
    margin-bottom: 25px;
    position: relative;
}

.section {
    margin-bottom: 20px;
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #000000;

    @include mobile {
        margin-bottom: 16px;
    }
}

.smallTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    color: #242424;
    margin-bottom: 6px;
}

.label {
    font-weight: 300;
    font-size: 16px;
    line-height: 100%;
    display: block;
    margin-bottom: 10px;
    color: #787878;
}

.button {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    height: 46px;
    background: #242424;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    color: #FFFFFF;
    transition: 0.3s all;

    svg {
        margin-right: 10px;
    }

    &:hover {
        background: #5a5a5a;
    }

    &.disabled {
        pointer-events: none;
        background: #D0D0D0;
    }
}