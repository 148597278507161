@import '../../utils/mixins.module.scss';


.image {
    height: 100%;
    width: 100%;
    position: relative;
    -o-object-fit: cover;
    object-fit: cover;
}

.slide {
    width: 300px;
    height: 100%;
    overflow: hidden;

    @include mobile {
        width: 70vw;
    }
}

.loader {
    height: 100%;
}

:global {
    .ril__toolbar {
        background-color: transparent;
    }
}