@import '../../utils/mixins.module.scss';

.swiper {
    margin-top: 20px;
    height: 260px;

    @include mobile {
        height: 200px;
    }
}

.wrapper {
    position: relative;
    margin-bottom: 80px;
    height: 100%;

    @include tablet-small {
        margin-bottom: 44px;
    }

    @include mobile {
        margin-bottom: 40px;
    }
}

.dotContainer {
    bottom: 5px !important;
}

.dot {
    background-color: rgba(243, 243, 243, 0.5);
    width: 5px;
    display: inline-block;
    height: 5px;
    border-radius: 50%;

    &:not(:last-of-type) {
        margin-right: 5px;
    }

    &.active {
        background-color: #F3F3F3;
    }
}

.arrow {
    background: #FFFFFF;
    box-shadow: -1px 2px 4px rgba(176, 176, 176, 0.25);
    border-radius: 100px;
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 100;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: -25px;
    transform: translateY(-50%);
    padding-right: 3px;
    transition: .3s all;
    opacity: 0.8;

    @include mobile {
        display: none;
    }

    &:hover {
        opacity: 1;
    }

    &.next {
        padding-right: 0;
        padding-left: 3px;
        left: unset;
        right: -25px;
    }
}

.nextArrow {
    transform: rotate(180deg);
}