@import '../../utils/mixins.module.scss';

.review {
    background: #F3F3F3;
    border: 1px solid #F4F4F4;
    padding: 20px;
}

.message {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-weight: 300;
    font-size: 16px;
    line-height: 170%;
    color: #000000;

    p {
        display: none;
    }
}

.title {
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 20px;
    color: #242424;

    @include mobile {
        margin-bottom: 10px;
    }
}

.readMore {
    font-weight: 300;
    font-size: 16px;
    line-height: 170%;
    color: #787878;
    cursor: pointer;
    margin-top: 2px;
}

.author {
    font-weight: 300;
    margin-top: 20px;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    color: #787878;

    @include mobile {
        margin-top: 10px;
    }
}