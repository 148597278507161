@import '../../utils/mixins.module.scss';

.jumbo {
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    height: 400px;

    @include tablet-small {
        height: 300px;
    }

    @include mobile {
        height: 200px;
    }
}