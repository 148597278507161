@import '../../utils/mixins.module.scss';

.jumboSplit {
    height: 430px;
    display: grid;
    grid-template-columns: 1fr 444px;
    gap: 10px;
    max-width: 100%;
    margin-bottom: 80px;

    @include desktop-large {
        grid-template-columns: 1fr 684px;
    }

    @include tablet-small {
        grid-template-columns: 1fr;
        margin-bottom: 44px;
        height: auto;
        gap: 15px;
    }

    @include mobile {
        gap: 0;
    }
}

.jumboImg {
    height: inherit;
    width: 100%;
    max-height: 100%;
    -o-object-fit: cover;
    object-fit: cover;

    @include tablet-small {
        height: 350px;
    }

    @include mobile {
        height: 236px;
    }
}

.jumboContent {
    background: #F3F3F3;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    flex-direction: column;

    @include tablet-small {
        justify-content: flex-start;
        padding: 20px;
    }
}

.subtext {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #787878;

    @include mobile {
        font-size: 14px;
    }
}

.jumboTitle {
    font-family: 'Adamina';
    font-weight: 400;
    max-width: 284px;
    font-size: 40px;
    line-height: 150%;
    margin: 10px 0;
    text-transform: uppercase;
    color: #242424;

    @include desktop-large {
        max-width: 474px;
    }

    @include tablet-small {
        max-width: 550px;
    }

    @include mobile {
        font-size: 28px;
        line-height: 150%;
        max-width: 343px;
    }
}

.jumboSubHeader {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #787878;

    @include tablet-small {
        line-height: 100%;
    }

    @include mobile {
        font-size: 14px;
        line-height: 150%;
    }
}

.aboutGrid {
    display: grid;
    margin-top: 20px;
    height: 628px;
    gap: 25px;
    align-items: center;
    grid-template-columns: 1fr 1fr;

    @include tablet-large {
        gap: 30px;
        height: 551px;
    }

    @include tablet-small {
        gap: 25px;
        height: auto;
        margin-bottom: 44px;
        grid-template-columns: 1fr;
    }
}

.aboutImage {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;

    @include tablet-small {
        height: 295px;
        width: 100%;
    }

    @include mobile {
        height: 218px;
    }
}

.content {
    padding: 40px;
    display: grid;
    grid-auto-flow: row;
    gap: 40px;

    @include tablet-large {
        padding: 0;
    }

    @include mobile {
        gap: 30px;
    }
}

.header {
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #1E1E1E;
}

.item {
    font-weight: 300;
    font-size: 16px;
    line-height: 160%;
    color: #242424;
}