@import '../../components/reviewBox/review.module.scss';
@import '../../utils/mixins.module.scss';

.wrapper {
    margin: 80px auto 30px;

    @include tablet-small {
        margin: 44px auto;
    }

    @include mobile {
        margin: 40px auto;
    }
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 20px;

    @include tablet-small {
        gap: 20px;
    }

    @include mobile {
        gap: 10px;
        grid-template-columns: 1fr;
    }
}

.overlayWrapper {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 1000;
    display: flex;
    align-items: center;
    opacity: 0;
    justify-content: center;
    visibility: hidden;
    transition: 0.15s all linear;

    @include mobile {
        align-items: flex-end;
    }

    &.active {
        opacity: 1;
        visibility: visible;
    }
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
}

.popup {
    background: #F3F3F3;
    border: 1px solid #F4F4F4;
    border-radius: 4px;
    padding: 20px;
    z-index: 1;
    width: 495px;
    position: relative;

    @include mobile {
        max-height: 50%;
        padding: 16px;
        border-radius: 8px 8px 0 0;
        overflow: auto;
    }
}

.message {
    display: block;

    p {
        display: block;
    }
}

.close {
    position: absolute;
    cursor: pointer;
    right: 20px;
    top: 18px;

    @include mobile {
        right: 16px;
        top: 14px;
    }
}