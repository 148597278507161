@import '../../utils/mixins.module.scss';

.nav {
    background: #FFFFFF;
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 101;
}

.hamburger {
    display: none;
    cursor: pointer;

    @include tablet-small {
        display: block;
    }
}

.navLink {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    position: relative;
    line-height: 27px;
    letter-spacing: 0.07em;
    text-decoration: none;
    text-transform: uppercase;
    color: #787878;

    @include tablet-large {
        font-size: 16px;
    }

    @include tablet-small {
        width: 100%;
        font-size: 30px;
    }

    @include mobile {
        font-size: 20px;
    }

    &::after {
        height: 1px;
        position: absolute;
        top: calc(100% + 3px);
        left: 0;
        width: 100%;
        content: "";
        transition: 0.15s all;
        background-color: transparent;
    }

    &.active,
    &:hover {

        &::after {
            background-color: #787878;

            @include tablet-small {
                display: none;
            }
        }

        @include tablet-small {
            font-weight: bold;
        }
    }
}

.logo {
    height: 50px;
    width: 107px;
}

.links {
    display: inline-grid;
    grid-auto-flow: column;
    gap: 30px;
    margin-top: -10px;
    height: 40px;
    align-items: center;
    margin-left: 45px;

    @include tablet-small {
        display: none;
        margin-left: 0;
        padding: 40px;
        margin-top: 0;
        display: grid;
        width: 100%;
        gap: 60px;
        z-index: 100;
        right: 0;
        align-content: center;
        grid-auto-rows: auto;
        align-items: center;
        justify-content: center;
        grid-auto-flow: row;
        background-color: #F3F3F3;
        height: calc(100% - 94px);
        top: -100%;
        text-align: center;
        position: fixed;
        transition: 0.3s all;
    }

    @include mobile {
        gap: 40px;
        height: calc(100% - 82px);
        padding: 30px 16px;
    }

    &.open {
        @include tablet-small {
            top: 94px;
        }

        @include mobile {
            top: 82px;
        }
    }
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 101;
    position: relative;

    @include tablet-small {
        width: 100%;
        padding: 22px 38px;
    }

    @include mobile {
        padding: 16px;
    }
}

.wrapper {
    max-width: 1280px;
    margin: 0 auto;
    padding: 22px 0;
    display: flex;
    align-items: center;
    width: 100%;

    @include desktop {
        padding: 22px 40px;
    }

    @include tablet-small {
        justify-content: space-between;
        padding: 0;
    }

    @include mobile {
        padding: 0;
        position: relative;
        z-index: 101;
        background-color: #fff;
    }
}