@import '../../utils/mixins.module.scss';

.wrapper {
    max-width: 1280px;
    margin: 0 auto;

    @include desktop {
        padding: 0 40px;
    }

    @include mobile {
        padding: 0 16px;
    }
}