@import '../../utils/mixins.module.scss';

.grid {
    margin: 80px auto;

    @include tablet-large {
        margin: 60px auto;
    }

    @include tablet-small {
        margin: 44px auto;
    }

    @include mobile {
        margin: 40px auto;
    }
}

.image {
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;

    @include mobile {
        height: 250px;
        order: 2;
    }
}

.gridRow {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    height: 400px;
    gap: 70px;

    @include tablet-large {
        height: 300px;
        gap: 40px;
    }

    @include mobile {
        grid-template-columns: 1fr;
        height: auto;
        gap: 20px;
    }

    &:not(:last-of-type) {
        margin-bottom: 80px;

        @include tablet-large {
            margin-bottom: 60px;
        }
    }

    &.reverse {
        grid-template-columns: auto 1fr;

        @include mobile {
            grid-template-columns: 1fr;
        }
    }
}

.title {
    font-weight: 500;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 160%;
    text-transform: uppercase;
    color: #000000;
}

.content {
    width: 380px;

    @include tablet-large {
        width: 360px;
    }

    @include tablet-small {
        width: 265px;
    }

    @include mobile {
        width: 100%;
    }

    ul {
        margin: 0;
        list-style: none;
        padding: 0;
    }

    li {
        position: relative;
        padding-left: 12px;
        font-weight: 300;
        font-size: 16px;
        line-height: 160%;
        color: #242424;

        &::before {
            content: "";
            width: 4px;
            height: 4px;
            left: 0;
            top: 11.5px;
            border-radius: 50%;
            background-color: #000000;
            position: absolute;
            display: inline-block;
        }

        &:not(:last-of-type) {
            margin-bottom: 3px;
        }
    }
}

.beforeTitle {
    margin-top: 80px;

    @include mobile {
        margin-top: 60px;
    }
}

.swiper {
    height: 500px;

    @include tablet-small {
        height: 300px;
    }

    @include mobile {
        height: auto;
    }
}