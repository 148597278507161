body {
  font-family: 'Poppins';
  margin: 0;

  &::-webkit-scrollbar {
    width: 10px;
    visibility: visible;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
    border: 3px solid white;
    border-radius: 8px;
  }
}

@font-face {
  font-family: 'Adamina';
  src: url(../public/fonts/Adamina-Regular.ttf);
}

@font-face {
  font-family: 'Poppins';
  src: url(../public/fonts/Poppins-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: url(../public/fonts/Poppins-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: 'Poppins';
  src: url(../public/fonts/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src: url(../public/fonts/Poppins-Bold.ttf);
  font-weight: 700;
}

h2 {
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  line-height: 27px;
  text-transform: uppercase;
  color: #787878;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}