@import '../../utils/mixins.module.scss';

.slide {
    display: grid;
    height: 100%;
    gap: 20px;
    grid-template-columns: 1fr 1fr;

    @include mobile {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }

    img {
        height: 100%;
    }
}

.image {
    height: 100%;
    max-height: 100%;
}

.side {
    position: relative;
    max-height: 100%;
    overflow: hidden;

    @include mobile {
        height: 245px;
    }
}

.label {
    position: absolute;
    right: 0;
    top: 30px;
    background: #F3F3F3;
    padding: 10px 20px;
    z-index: 1;
    font-weight: 500;
    font-size: 22px;
    line-height: 150%;
    text-transform: uppercase;
    color: #242424;

    @include mobile {
        padding: 5px 15px;
        top: 20px;
        font-size: 18px;
    }
}